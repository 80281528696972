.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #808080;
}

.mainContent {
  background-color: white;
  padding-left: 0px;
  padding-right: 0px;
}

header {
  background-image: url('./images/LogoBackground.jpg');
}


.float_left {
	float:left;
	clear:right;
	padding-right:1em;  
}

.float_right {
	float:right;
	clear:left;
	padding-left:1em;
}

.archive-list {
  overflow-y: auto;
  max-height: 60vh;
  margin-top:10px;
}

.question {
  font-style: italic;
}
